<template>
  <v-dialog v-model="show" :width="width">
    <v-card>
      <v-card-title>
        {{title}}
      </v-card-title>
      <v-card-text>
        {{message}}
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" depressed @click="click">
          {{buttonLabel}}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import EventBus from '@/utils/event-bus';

export default {
  computed: {
    show: {
      get() {
        return this.$store.state.alert.show;
      },
      set(value) {
        this.$store.commit('alert/setShow', value);
      }
    },
    width: {
      get() {
        switch (this.$vuetify.breakpoint.name) {
          case 'xs':
            return 290;
          case 'sm':
            return 400;
          case 'md':
            return 500;
          case 'lg':
            return 600;
          case 'xl':
            return 800;
        }
        return 290;
      }
    },
    ...mapGetters('alert', {
      buttonLabel: 'getButtonLabel',
      message: 'getMessage',
      title: 'getTitle',
      callback: 'getCallback',
    }),
  },
  methods: {
    click() {
      this.show = false;
      this.callback();
    },
  },
  mounted() {
    EventBus.$on('ShowAlert', payload => {
      this.$store.commit('alert/set', payload);
    });
  }
}
</script>
