import axios from 'axios';
import EventBus from './event-bus';
import vuetify from '@/plugins/vuetify';

class Client {
	constructor(baseUrl = process.env.VUE_APP_API_URL) {
		if(baseUrl.slice(-1) !== '/') {
			baseUrl += '/';
		}
		this.baseUrl = baseUrl;
		axios.interceptors.response.use(function (response) {
			return response;
		}, function (error) {
			let errorMessage = '';
			if(typeof error.response === 'undefined') {
				errorMessage = vuetify.framework.lang.t('$vuetify.netzwerk_fehler');
			} else if(error.response.data && error.response.data.message) {
				errorMessage = error.response.data.message;
			} else if(error.response.status === 401) {
				errorMessage = vuetify.framework.lang.t('$vuetify.sitzung_abgelaufen');
			} else {
				errorMessage = vuetify.framework.lang.t('$vuetify.unbekannter_fehler');
			}
			EventBus.$emit('ShowSnackbar', {
				show: true,
				color: 'error',
				message: errorMessage,
			});
			return Promise.reject(error);
		});
	}

	request(method, path, params = {}, data = {}, accept = '') {
		path = path.charAt(0) === '/' ? path.substr(1) : path;
		let headers = {};
		if(localStorage.getItem('token')) {
			headers.Authorization = 'Bearer ' + localStorage.getItem('token');
		}
		if(accept) {
			headers.Accept = accept;
		}
		let config = {
			method: method,
			url: this.baseUrl + path,
			params: params,
			data: data,
			headers: headers,
		};
		if(accept && accept !== 'application/json') {
			config.responseType = 'blob';
		}
		return axios.request(config);
	}

	get(path, params = {}, accept = '') {
		return this.request('get', path, params, {}, accept);
	}

	post(path, data = {}, params = {}, accept = '') {
		return this.request('post', path, params, data, accept);
	}

	put(path, data = {}, params = {}, accept = '') {
		return this.request('put', path, params, data, accept);
	}

	delete(path, params = {}) {
		return this.request('delete', path, params);
	}
}

export default new Client();
