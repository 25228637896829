<template>
  <v-dialog v-model="open" max-width="600">
    <v-card>
      <v-card-title>
        {{ $vuetify.lang.t('$vuetify.legal.impressum') }}
        <v-spacer></v-spacer>
        <v-btn @click="open = false" depressed icon>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <p>
          Name Gesellschaft:<br/>
          SKIBATRON Mess- und Abrechnungssysteme GmbH<br/>
          Bergmannsglückstraße 35<br/>
          45896 Gelsenkirchen
        </p>

        <p>
          Vertretungsberechtigte GF:<br/>
          Dr. Ralf Brauksiepe<br/>
          Uwe Eichner<br/>
          Roland Viets
        </p>

        <p>
          Sitz der Gesellschaft:<br/>
          Gelsenkirchen
        </p>

        <p>
          Mail:<br/>
          <a href="mailto:info@skibatron.de">info@skibatron.de</a>
        </p>

        <p>
          Telefon:<br/>
          <a href="tel:+4920935975500">+49 209 359-75500</a>
        </p>

        <p>
          Registergericht:<br/>
          Amtsgericht Gelsenkirchen, HRB 14400
        </p>

        <p>
          Registernummer:<br/>
          HRB 14400
        </p>

        <p>
          USt-Ident-Nr gem. § 27 EstG:<br/>
          DE 126687666
        </p>

        <p>
          Teilnahme am Streitschlichtungsverfahren:<br/>
          Wir sind zur Teilnahme an einem Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle weder bereit noch verpflichtet.
        </p>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ['value'],
  emits: ['input'],
  computed: {
    open: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    },
  },
};
</script>
