export default [
	{
		path: '/login',
		meta: {
			layout: 'LoginLayout',
			guestOnly: true,
		}
	},
	{
		path: '/login/:token',
		name: 'DirectLogin',
		meta: {
			layout: 'LoginLayout',
			guestOnly: true,
		}
	},
	{
		path: '/optin/:token',
		name: 'OptIn',
		meta: {
			layout: 'LoginLayout',
			guestOnly: true,
		}
	},
	{
		path: '/dashboard',
		meta: {
			authenticationRequired: true,
		}
	},
	{
		path: '/heizenergie',
		meta: {
			authenticationRequired: true,
		}
	},
	{
		path: '/warmwasser',
		meta: {
			authenticationRequired: true,
		}
	},
	{
		path: '/kaltwasser',
		meta: {
			authenticationRequired: true,
		}
	},
	{
		path: '/faq',
		name: 'FAQ',
		meta: {
			authenticationRequired: true,
		}
	},
	{
		path: '/mitbenutzer',
		name: 'Mitbenutzer',
		meta: {
			authenticationRequired: true,
		}
	},
	{
		path: '/anmeldedaten',
		meta: {
			authenticationRequired: true,
		}
	},
	{
		path: '/logout',
		meta: {
			authenticationRequired: true,
		}
	}
];
