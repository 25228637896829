import Vue from 'vue';
import vuetify from '@/plugins/vuetify';
import store from '@/stores';
import router from '@/router';
import App from '@/App.vue';
import { Chart, registerables } from 'chart.js';
Chart.register(...registerables);

import NoLayout from '@/layouts/NoLayout';
import DefaultLayout from '@/layouts/DefaultLayout';
import LoginLayout from '@/layouts/LoginLayout';
Vue.component('NoLayout', NoLayout);
Vue.component('DefaultLayout', DefaultLayout);
Vue.component('LoginLayout', LoginLayout);

import '@mdi/font/css/materialdesignicons.css';

Vue.config.productionTip = false;

router.beforeEach((to, from, next) => {
  if(to.matched.some(record => record.meta.authenticationRequired)) {
    if(checkAuthenticated()) {
      next();
    } else {
      localStorage.removeItem('token');
      localStorage.removeItem('vuex');
      next({name:'Login'});
    }
  } else if(to.matched.some(record => record.meta.guestOnly)) {
    if(localStorage.getItem('token')) {
      next({name:'Dashboard'});
    } else {
      next();
    }
  } else {
    next();
  }
});

function checkAuthenticated() {
  let jwt = localStorage.getItem('token');
  if (!jwt || !store.state.user.id) {
    return false;
  }
  let data = JSON.parse(atob(jwt.split('.')[1]));
  let now = Math.round(new Date().getTime() / 1000);
  return !(!data.exp || data.exp < now);
}

new Vue({
  router,
  vuetify,
  store,
  render: h => h(App),
}).$mount('#app');
