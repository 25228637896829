import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import user from '@/stores/modules/user';
import vertrag from '@/stores/modules/vertrag';
import snackbar from '@/stores/modules/snackbar';
import alert from '@/stores/modules/alert';

Vue.use(Vuex);

export default new Vuex.Store({
	modules: {
		user,
		snackbar,
		alert,
		vertrag
	},
	plugins: [createPersistedState({storage: window.localStorage, paths: ['user', 'vertrag']})]
});
