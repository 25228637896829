<template>
  <div>
    <v-navigation-drawer
        v-model="drawer"
        app
        floating
        class="drawer"
        :permanent="$vuetify.breakpoint.mdAndUp"
        :absolute="$vuetify.breakpoint.smAndDown"
    >
      <v-list dense>
        <v-list-item two-line v-if="vertraege.length > 1" link @click="contractSwitchDialog = true">
          <v-list-item-icon>
            <v-icon>mdi-swap-horizontal</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ $vuetify.lang.t('$vuetify.navigation.vertrag_wechseln') }}</v-list-item-title>
            <v-list-item-subtitle>
              {{vertrag.wohneinheit.geschosslage_kd}}<br/>
              {{vertrag.wohneinheit.gebaeude.ort}}, {{vertrag.wohneinheit.gebaeude.strasse}} {{vertrag.wohneinheit.gebaeude.hausnr}}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <template v-for="item in items">
          <v-list-item
              :key="item.title"
              :to="item.target"
              link
              v-if="!item.hide"
          >
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ $vuetify.lang.t('$vuetify.' + item.title) }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
      <ul class="legal-links mt-10">
        <li>
          <a :href="impressumUrl" target="_blank">{{ $vuetify.lang.t('$vuetify.legal.impressum') }}</a>
        </li>
        <li>
          <a :href="datenschutzUrl" target="_blank">{{ $vuetify.lang.t('$vuetify.legal.datenschutz') }}</a>
        </li>
        <li>
          <a @click="openKontaktDialog()">{{ $vuetify.lang.t('$vuetify.legal.kontakt') }}</a>
        </li>
      </ul>
    </v-navigation-drawer>
    <v-dialog v-model="contractSwitchDialog" width="290">
      <v-card>
        <v-card-title>
          {{ $vuetify.lang.t('$vuetify.navigation.vertrag_wechseln') }}
        </v-card-title>
        <v-list dense>
          <v-list-item-group v-model="vertrag_id" color="primary">
            <template v-for="(item) in user.vertraege">
              <v-list-item two-line :key="item.id" :value="item.id" @click="switchContract(item)" link>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ $vuetify.lang.t('$vuetify.navigation.vertrags_nr', item.vertragsnr) }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{item.wohneinheit.geschosslage_kd}}<br/>
                    {{item.wohneinheit.gebaeude.ort}}, {{item.wohneinheit.gebaeude.strasse}} {{item.wohneinheit.gebaeude.hausnr}}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-divider :key="item.id + '-divider'"></v-divider>
            </template>
            <template v-for="(item) in user.mitvertraege">
              <v-list-item two-line :key="item.id" :value="item.id" @click="switchContract(item)" link>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ $vuetify.lang.t('$vuetify.navigation.vertrags_nr', item.vertragsnr) }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{item.wohneinheit.geschosslage_kd}}<br/>
                    {{item.wohneinheit.gebaeude.ort}}, {{item.wohneinheit.gebaeude.strasse}} {{item.wohneinheit.gebaeude.hausnr}}
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-avatar>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon v-bind="attrs" v-on="on">mdi-account-multiple</v-icon>
                    </template>
                    <span>{{ $vuetify.lang.t('$vuetify.navigation.mitbenutzer_info') }}</span>
                  </v-tooltip>
                </v-list-item-avatar>
              </v-list-item>
              <v-divider :key="item.id + '-divider'"></v-divider>
            </template>
          </v-list-item-group>
        </v-list>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              text
              @click="contractSwitchDialog = false"
          >
            {{ $vuetify.lang.t('$vuetify.abbrechen') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <ImpressumDialog v-model="impressumDialog"/>
    <Kontakt :open="kontaktDialog" @close="kontaktDialog = false"/>
  </div>
</template>

<style lang="sass">
.v-list--dense .v-list-item .v-list-item__subtitle
  font-size: 12px
  font-weight: 300

@media only screen and (min-width: 960px)
  .drawer
    box-shadow: 0 0 10px #00000033
  .v-navigation-drawer__border
    display: none

ul.legal-links
  margin: 0
  padding: 0
  list-style-type: none
  > li
    padding: 6px 16px
    > a
      font-size: 13px
      text-decoration: none
      &:hover
        text-decoration: underline
</style>

<script>
import EventBus from '@/utils/event-bus';
import ImpressumDialog from '@/components/ImpressumDialog';
import Kontakt from '@/components/Kontakt';
import { mapState } from 'vuex';

export default {
  components: {Kontakt,ImpressumDialog},
  data: () => ({
    drawer: false,
    mini: false,
    contractSwitchDialog: false,
    vertrag_id: null,
    kontaktDialog: false,
    datenschutzUrl: '/assets/documents/Datenschutzhinweise.pdf',
    agbUrl: '/assets/documents/agb.pdf',
    impressumUrl: false,
    impressumDialog: false,
  }),
  computed: {
    ...mapState({
      vertrag: state => state.vertrag,
      user: state => state.user,
    }),
    items: function() {
      let items = [{ title: 'navigation.dashboard', icon: 'mdi-clock-outline', target: '/dashboard', }];
      if(this.vertrag.wohneinheit.heizenergie) {
        items.push({ title: 'navigation.heizenergie', icon: 'mdi-fire', target: '/heizenergie', });
      }
      if(this.vertrag.wohneinheit.warmwasser) {
        items.push({ title: 'navigation.warmwasser', icon: 'mdi-water-plus', target: '/warmwasser', });
      }
      if(this.vertrag.wohneinheit.kaltwasser) {
        items.push({ title: 'navigation.kaltwasser', icon: 'mdi-water-minus', target: '/kaltwasser', });
      }
      items.push({ title: 'navigation.faq', icon: 'mdi-lightbulb', target: '/faq', });
      items.push({ title: 'navigation.mitbenutzer', icon: 'mdi-account-multiple', target: '/mitbenutzer', hide: this.$store.getters["vertrag/isMitbenutzer"] });
      return items;
    },
    vertraege: function() {
      const vertraege = [];
      if(this.user.vertraege) {
        vertraege.push(...this.user.vertraege);
      }
      if(this.user.mitvertraege) {
        vertraege.push(...this.user.mitvertraege);
      }
      return vertraege;
    }
  },
  watch: {
    '$store.state.vertrag.id': {
      immediate: true,
      handler() {
        this.vertrag_id = this.vertrag.id;
      }
    }
  },
  mounted () {
    EventBus.$on('toggleNavigation', () => {
      window.scrollTo(0,0);
      this.drawer = !this.drawer;
    });
    EventBus.$on('changeVermieter', vermieter => {
      if(vermieter.datenschutz) {
        this.datenschutzUrl = process.env.VUE_APP_API_URL + '/var/uploads/' + vermieter.datenschutz;
      } else {
        this.datenschutzUrl = '/assets/documents/Datenschutzhinweise.pdf';
      }
      if(vermieter.agb) {
        this.agbUrl = process.env.VUE_APP_API_URL + '/var/uploads/' + vermieter.agb;
      } else {
        this.agbUrl = '/assets/documents/agb.pdf';
      }
      if(vermieter.impressum) {
        this.impressumUrl = process.env.VUE_APP_API_URL + '/var/uploads/' + vermieter.impressum;
      } else {
        this.impressumUrl = false;
      }
    });
  },
  methods: {
    switchContract(item) {
      this.$store.commit('vertrag/load', item);
      this.contractSwitchDialog = false;
      let vermieter = item.wohneinheit.gebaeude.liegenschaft.vermieter;
      EventBus.$emit('changeVermieter', vermieter);
    },
    openKontaktDialog() {
      this.kontaktDialog = true;
    },
    openImpressum() {
      if(this.impressumUrl) {
        window.open(this.impressumUrl);
      } else {
        this.impressumDialog = true;
      }
    }
  }
};
</script>
