export default {
	namespaced: true,
	state: {
		color: null,
		timeout: 15000,
		show: false,
		message: '',
	},
	getters: {
		getColor: state => state.color,
		getTimeout: state => state.timeout,
		getShow: state => state.show,
		getMessage: state => state.message,
	},
	mutations: {
		set(state, data) {
			Object.assign(state, data);
		},
		setShow(state, value) {
			state.show = value;
		},
		setTimeout(state, value) {
			state.timeout = value;
		},
		setMessage(state, value) {
			state.message = value;
		},
		setColor(state, value) {
			state.color = value;
		},
	}
}
