export default {
	namespaced: true,
	state: {
		id: null,
		vertragsnr: null,
		beginn: null,
		ende: null,
		wohneinheit: {},
		mitbenutzer: null,
	},
	mutations: {
		load(state, data) {
			Object.keys(state).forEach(key => {
				if(typeof data[key] === 'undefined') {
					data[key] = null;
				}
			});
			Object.assign(state, data);
		},
		reset(state) {
			Object.keys(state).forEach(key => {
				delete state[key];
			});
		},
		setId(state, value) {
			state.id = value;
		},
		setVertragsnr(state, value) {
			state.vertragsnr = value;
		},
		setBeginn(state, value) {
			state.beginn = value;
		},
		setEnde(state, value) {
			state.ende = value;
		},
		setWohneinheit(state, value) {
			state.wohneinheit = value;
		},
		setMitbenutzer(state, value) {
			state.mitbenutzer = value;
		},
	},
	getters: {
		getId: state => state.id,
		getVertragsnr: state => state.vertragsnr,
		getBeginn: state => state.beginn,
		getEnde: state => state.ende,
		getWohneinheit: state => state.wohneinheit,
		getMitbenutzer: state => state.mitbenutzer,
		isMitbenutzer: (state, _, rootState) => {
			return rootState.user.mitvertraege.some(vertrag => vertrag.id === state.id);
		}
	},
	actions: {
		destroy({ commit }) {
			commit('reset');
		}
	}
}
