import { de } from 'vuetify/lib/locale'

export default {
	...de,
	netzwerk_fehler: "Netzwerkfehler",
	sitzung_abgelaufen: "Ihre Sitzung ist abgelaufen oder Sie haben keine Berechtigung.",
	unbekannter_fehler: "Es ist ein unbekannter Fehler aufgetreten. Bitte versuchen Sie es später erneut.",
	abbrechen: "Abbrechen",
	ok: "OK",
	speichern: "Speichern",
	ja: "Ja",
	nein: "Nein",
	schliessen: "Schließen",
	impersonation_banner: "Impersonation-Session: Sie sind gerade als Benutzer {0} eingeloggt",
	navigation: {
		dashboard: "Dashboard",
		heizenergie: "Heizenergie",
		kaltwasser: "Kaltwasser",
		warmwasser: "Warmwasser",
		mitbenutzer: "Mitbenutzer",
		faq: "FAQ",
		vertrag_wechseln: "Vertrag auswählen",
		vertrags_nr: "Vertrags-Nr.: {0}",
		mitbenutzer_info: "Sie sind als Mitbenutzer zu diesem Vertrag hinterlegt.",
	},
	header: {
		logout: "Abmelden",
		anmeldedaten: "Anmeldedaten"
	},
	legal: {
		impressum: "Impressum",
		datenschutz: "Datenschutz",
		agb: "Allgemeine Geschäftsbedingungen",
		kontakt: "Kontaktanfrage",
	},
	login: {
		willkommen: "Herzlich Willkommen",
		portal: "Ihr Verbrauchs&shy;portal",
		anmelden: "Anmelden",
		email: "E-Mail",
		passwort: "Passwort",
		passwort_erforderlich: "Bitte geben Sie Ihr Passwort ein.",
		passwort_vergessen: "Passwort vergessen?",
		email_erforderlich: "Bitte geben Sie Ihre E-Mail-Adresse ein.",
		email_ungueltig: "Die E-Mail-Adresse ist ungültig.",
		passwort_vergessen_dialog: "Passwort vergessen",
		passwort_vergessen_dialog_hinweis: "Falls Sie Ihre E-Mail-Adresse vergessen haben, wenden Sie sich bitte an den Kundensupport.",
		passwort_vergessen_anfordern_button: "Passwort anfordern",
		passwort_vergessen_erfolg: "Sie erhalten in Kürze eine E-Mail mit einem Link, über den Sie ein neues Passwort vergeben können.",
		passwort_vergessen_fehler: "Etwas ist schiefgelaufen. Bitte versuchen Sie es erneut!",
		portal_beschreibung: "In Ihrem Verbrauchsportal finden Sie jederzeit alle Details rund um Ihren unterjährigen Verbrauch. Behalten Sie den Überblick über Ihre Verbräuche, vergleichen Sie diese mit dem Vorjahr und holen Sie sich hilfreiche Tipps rund um das Sparen von Wasser oder Heizenergie. In einem umfangreichen Fragen-Antwort-Katalog (FAQ) finden Sie viele Anleitungen und Erklärungen rund um das Portal und seine Funktionen. Melden Sie sich einfach mit Ihrem bereits vergebenen Passwort an. Sollten Sie Ihr Passwort vergessen haben, können Sie über die Funktion „Passwort vergessen“ ein Neues anfordern und so Ihren Zugang zurücksetzen bzw. Ihren Registrierungsprozess abschließen.",
		id_gespeichert: "Die passwortlose Anmeldung wurde erfolgreich eingerichtet.",
		id_entfernt: "Die passwortlose Anmeldung wurde erfolgreich zurückgesetzt.",
		public_key_hinweis: "Möchten Sie die passwortlose Anmeldung über Face ID, Touch ID, Windows Hello oder einem externen Sicherheitstoken einrichten? Bitte beachten Sie, dass Ihr Gerät die Funktion unterstützen muss.",
		loading: "Lade Daten …"
	},
	optIn: {
		passwort_vergeben: "Passwort vergeben",
		passwort_vergeben_text: "Vergeben Sie hier ein starkes Passwort für Ihr Benutzerkonto:",
		passwort: "Neues Passwort",
		passwort_wiederholen: "Passwort wiederholen",
		passwort_speichern_button: "Passwort speichern",
		passwort_ungueltig: "Bitte verwenden Sie mindestens einen Kleinbuchstaben, einen Großbuchstaben, eine Ziffer und ein Sonderzeichen.",
		passwort_erforderlich: "Bitte geben Sie ein starkes Passwort ein.",
		passwort_wiederholen_erforderlich: "Bitte wiederholen Sie zur Sicherheit Ihre Eingabe.",
		passwort_unterschied: "Die Eingaben stimmen nicht überein.",
		passwort_gespeichert_titel: "Passwort gespeichert",
		passwort_gespeichert_nachricht: "Das Passwort wurde gespeichert. Sie können sich nun mit Ihrem Passwort anmelden.",
		token_abgelaufen_titel: "Link abgelaufen",
		token_abgelaufen_text: "Der Link ist leider abgelaufen und kann nicht mehr verwendet werden. Sie können sich über die Passwort vergessen-Funktion einen neuen Link zusenden lassen."
	},
	dashboard: {
		willkommen: "Guten Tag, {0} {1}!",
		beschreibung: "Hier finden Sie eine Übersicht über Ihre aktuellen Verbräuche sowie hilfreiche Tipps und Tricks zum Sparen von Wasser oder Heizenergie. Unter „Zum Bericht“ erhalten Sie noch detailliertere Informationen zu Ihrem Verbrauch.",
		heizenergie_verbrauch: "Ihr Heizenergieverbrauch (HE)",
		verbrauch_aktuell: "Verbrauch aktuell",
		verbrauch_vorjahr: "Verbrauch Vorjahr",
		verbrauch_eingeschraenkt: "Verbrauch mit Einschränkungen *",
		heizenergie_letzter_monat: "Heizenergie im {0}",
		kaltwasser_letzter_monat: "Kaltwasserverbrauch im {0}",
		warmwasser_letzter_monat: "Warmwasserverbrauch im {0}",
		verbrauch_vorjahresvergleich: "Verbrauch im Vorjahresvergleich",
		ve: "VE",
		m3: "m³",
		kwh: "kWh",
		zum_bericht: "Zum Bericht",
		na: "N/A",
		unterhalb: "unterhalb",
		oberhalb: "oberhalb",
		heizenergie_liegenschaft_vergleich_text_oberhalb: "Ihr Heizenergieverbrauch im {0} liegt ca. {1}% über dem eines Durchschnittsnutzers.",
		kaltwasser_liegenschaft_vergleich_text_oberhalb: "Ihr Kaltwasserverbrauch im {0} liegt ca. {1}% über dem eines Durchschnittsnutzers.",
		warmwasser_liegenschaft_vergleich_text_oberhalb: "Ihr Warmwasserverbrauch im {0} liegt ca. {1}% über dem eines Durchschnittsnutzers.",
		heizenergie_liegenschaft_vergleich_text_unterhalb: "Ihr Heizenergieverbrauch im {0} liegt ca. {1}% unter dem eines Durchschnittsnutzers.",
		kaltwasser_liegenschaft_vergleich_text_unterhalb: "Ihr Kaltwasserverbrauch im {0} liegt ca. {1}% unter dem eines Durchschnittsnutzers.",
		warmwasser_liegenschaft_vergleich_text_unterhalb: "Ihr Warmwasserverbrauch im {0} liegt ca. {1}% unter dem eines Durchschnittsnutzers.",
		aktuelles_jahr: "Aktuelles Jahr",
		vorjahr: "Vorjahr",
		heizenergie_vorjahr_vergleich_text_mehr: "Im {0} haben Sie im Vergleich zum Vorjahresmonat {1}% mehr Heizenergie verbraucht.",
		heizenergie_vorjahr_vergleich_text_weniger: "Im {0} haben Sie im Vergleich zum Vorjahresmonat {1}% weniger Heizenergie verbraucht.",
		kaltwasser_vorjahr_vergleich_text_mehr: "Im {0} haben Sie im Vergleich zum Vorjahresmonat {1}% mehr Kaltwasser verbraucht.",
		kaltwasser_vorjahr_vergleich_text_weniger: "Im {0} haben Sie im Vergleich zum Vorjahresmonat {1}% weniger Kaltwasser verbraucht.",
		warmwasser_vorjahr_vergleich_text_mehr: "Im {0} haben Sie im Vergleich zum Vorjahresmonat {1}% mehr Warmwasser verbraucht.",
		warmwasser_vorjahr_vergleich_text_weniger: "Im {0} haben Sie im Vergleich zum Vorjahresmonat {1}% weniger Warmwasser verbraucht.",
		vertrag_ausgelaufen: "Ihr Mietvertrag ist zum {0} beendet.",
		letzter_monat: "letzten Monat",
	},
	datum: {
		monat_1: "Januar",
		monat_2: "Februar",
		monat_3: "März",
		monat_4: "April",
		monat_5: "Mai",
		monat_6: "Juni",
		monat_7: "Juli",
		monat_8: "August",
		monat_9: "September",
		monat_10: "Oktober",
		monat_11: "November",
		monat_12: "Dezember",
		short_monat_1: "Jan",
		short_monat_2: "Feb",
		short_monat_3: "Mär",
		short_monat_4: "Apr",
		short_monat_5: "Mai",
		short_monat_6: "Jun",
		short_monat_7: "Jul",
		short_monat_8: "Aug",
		short_monat_9: "Sep",
		short_monat_10: "Okt",
		short_monat_11: "Nov",
		short_monat_12: "Dez",
	},
	heizenergie: {
		header: "Heizenergie",
		kwh_switch: "Anzeige in kWh",
		kwh_tooltip: "Ermittlung: Verbrauch x Basisempfindlichkeitswert = Verbrauch in kWh (rechnerisch ermittelt)",
		verbrauch_nach_raeumen: "Verbrauch nach Räumen",
		alle_raeume: "Alle Räume",
		vergleich_vorjahr: "Vergleich Vorjahr",
		vergleich_liegenschaft: "Vergleich Liegenschaft",
		verbrauch_letzte_12_monate: "Verbrauch der letzten 12 Monate in {0}",
		ve: "VE",
		kwh: "kWh",
		raum_geraet_nr: "Raum / Zähler-Nr.",
		umrechen_faktor: "Umrechnungsfaktor",
		geraete_dialog_title: "Individuelle Raumbezeichnung",
		geraete_dialog_hinweis: "Ihre Änderungen der Raumbezeichnung sind individuell und nicht auf der Nebenkostenabrechnung zu finden.",
		geraete_nr: "Zählernummer",
		geraete_bezeichnung: "Raumbezeichnung",
		labels_speichern_erfolgreich: "Die individuellen Raumbezeichnungen wurden erfolgreich gespeichert.",
		gesamt: "Gesamt",
		export_verbrauch: "Heizenergieverbrauch_{0}_{1}-{2}_{3}_{4}.{5}",
		excel_export: "Excel-Export",
		keine_daten_vorhanden: "- noch keine Daten verfügbar -",
		fehler_messwert_1: "Für dieses Gerät ist leider keine Darstellung des Verbrauchs möglich.",
		fehler_messwert_2: "Leider ist aktuell keine Darstellung des Verbrauchs möglich. Bitte versuchen Sie es später noch einmal.",
		fehler_messwert_3: "Für diesen Zähler ist leider aktuell keine Darstellung des Verbrauchs möglich.",
		fehler_messwert_11: "Aufgrund einer technischen Störung erfolgt die Verbrauchsanzeige für dieses Gerät aus der Summe des Vormonats und des aktuellen Monats.",
		hinweis_fehler_verbrauch: "* Der Verbrauch für diesen Monat steht nur mit Einschränkungen zur Verfügung. Bitte beachten Sie die Hinweise in der folgenden Einzelaufstellung der Verbrauchswerte.",
		keine_daten_short: "",
		erklaerung_rechnung: "Rechnerische Ermittlung des Verbrauchs:<br/><br/>Ablesewert x Umrechnungsfaktor Vormonat<br/>- Ablesewert x Umrechnungsfaktor aktueller Monat<br/>= Verbrauch aktueller Monat<br/><br/>Den Umrechnungsfaktor und die Ablesewerte der vergangenen 12 Monate finden Sie in der Excel-Tabelle zum Download.",
		portalunfaehige_geraete: "In Ihrer Wohneinheit befinden sich weitere Messstellen, die aufgrund der örtlichen Gegebenheiten nicht mit Messtechnik ausgestattet werden konnten und in der Tabelle nicht angezeigt werden.",
	},
	kaltwasser: {
		header: "Kaltwasser",
		verbrauch_nach_raeumen: "Verbrauch nach Räumen",
		alle_raeume: "Alle Räume",
		vergleich_vorjahr: "Vergleich Vorjahr",
		vergleich_liegenschaft: "Vergleich Liegenschaft",
		verbrauch_letzte_12_monate: "Verbrauch der letzten 12 Monate in m³",
		raum_geraet_nr: "Raum / Zähler-Nr.",
		umrechen_faktor: "Umrechnungsfaktor",
		geraete_dialog_title: "Individuelle Raumbezeichnung",
		geraete_dialog_hinweis: "Ihre Änderungen der Raumbezeichnung sind individuell und nicht auf der Nebenkostenabrechnung zu finden.",
		geraete_nr: "Zählernummer",
		geraete_bezeichnung: "Raumbezeichnung",
		labels_speichern_erfolgreich: "Die individuellen Raumbezeichnungen wurden erfolgreich gespeichert.",
		gesamt: "Gesamt",
		m3: "m³",
		kwh: "kWh",
		export_verbrauch: "Kaltwasserverbrauch_{0}_{1}-{2}_{3}_{4}.{5}",
		excel_export: "Excel-Export",
		keine_daten_vorhanden: "- noch keine Daten verfügbar -",
		fehler_messwert_1: "Für dieses Gerät ist leider keine Darstellung des Verbrauchs möglich.",
		fehler_messwert_2: "Leider ist aktuell keine Darstellung des Verbrauchs möglich. Bitte versuchen Sie es später noch einmal.",
		fehler_messwert_3: "Für diesen Zähler ist leider aktuell keine Darstellung des Verbrauchs möglich.",
		fehler_messwert_11: "Aufgrund einer technischen Störung erfolgt die Verbrauchsanzeige für dieses Gerät aus der Summe des Vormonats und des aktuellen Monats.",
		hinweis_fehler_verbrauch: "* Der Verbrauch für diesen Monat steht nur mit Einschränkungen zur Verfügung. Bitte beachten Sie die Hinweise in der folgenden Einzelaufstellung der Verbrauchswerte.",
		keine_daten_short: "",
		erklaerung_rechnung: "",
		portalunfaehige_geraete: "In Ihrer Wohneinheit befinden sich weitere Messstellen, die aufgrund der örtlichen Gegebenheiten nicht mit Messtechnik ausgestattet werden konnten und in der Tabelle nicht angezeigt werden.",
	},
	warmwasser: {
		header: "Warmwasser",
		verbrauch_nach_raeumen: "Verbrauch nach Räumen",
		alle_raeume: "Alle Räume",
		vergleich_vorjahr: "Vergleich Vorjahr",
		vergleich_liegenschaft: "Vergleich Liegenschaft",
		verbrauch_letzte_12_monate: "Verbrauch der letzten 12 Monate in {0}",
		raum_geraet_nr: "Raum / Zähler-Nr.",
		umrechen_faktor: "Umrechnungsfaktor",
		geraete_dialog_title: "Individuelle Raumbezeichnung",
		geraete_dialog_hinweis: "Ihre Änderungen der Raumbezeichnung sind individuell und nicht auf der Nebenkostenabrechnung zu finden.",
		geraete_nr: "Zählernummer",
		geraete_bezeichnung: "Raumbezeichnung",
		labels_speichern_erfolgreich: "Die individuellen Raumbezeichnungen wurden erfolgreich gespeichert.",
		gesamt: "Gesamt",
		export_verbrauch: "Warmwasserverbrauch_{0}_{1}-{2}_{3}_{4}.{5}",
		excel_export: "Excel-Export",
		keine_daten_vorhanden: "- noch keine Daten verfügbar -",
		m3: "m³",
		kwh: "kWh",
		kwh_switch: "Anzeige in kWh",
		kwh_tooltip: "Rechnerische Ermittlung der kWh durch Verbrauch in m³ x Faktor (1,163) x Temperaturdifferenz (10 Grad Kaltwasser und 60 Grad Warmwasser = 50 Grad).",
		fehler_messwert_1: "Für dieses Gerät ist leider keine Darstellung des Verbrauchs möglich.",
		fehler_messwert_2: "Leider ist aktuell keine Darstellung des Verbrauchs möglich. Bitte versuchen Sie es später noch einmal.",
		fehler_messwert_3: "Für diesen Zähler ist leider aktuell keine Darstellung des Verbrauchs möglich.",
		fehler_messwert_11: "Aufgrund einer technischen Störung erfolgt die Verbrauchsanzeige für dieses Gerät aus der Summe des Vormonats und des aktuellen Monats.",
		hinweis_fehler_verbrauch: "* Der Verbrauch für diesen Monat steht nur mit Einschränkungen zur Verfügung. Bitte beachten Sie die Hinweise in der folgenden Einzelaufstellung der Verbrauchswerte.",
		keine_daten_short: "",
		erklaerung_rechnung: "",
		portalunfaehige_geraete: "In Ihrer Wohneinheit befinden sich weitere Messstellen, die aufgrund der örtlichen Gegebenheiten nicht mit Messtechnik ausgestattet werden konnten und in der Tabelle nicht angezeigt werden.",
	},
	faq: {
		ueberschrift: "Nutzung des Verbrauchsportals und technische Fragestellungen"
	},
	anmeldedaten: {
		ueberschrift: "Anmeldedaten",
		email_aendern: "E-Mail-Adresse ändern",
		email: "E-Mail-Adresse",
		passwort_aendern: "Passwort ändern",
		neues_passwort: "Neues Passwort",
		passwort_wiederholen: "Passwort wiederholen",
		email_gespeichert: "Ihre E-Mail-Adresse wurde erfolgreich geändert!",
		passwort_gespeichert: "Ihr Passwort wurde erfolgreich geändert!",
		public_key: "Passwortlose Anmeldung",
		anlegen_button: "Anlegen",
		entfernen_button: "Auf diesem Gerät zurücksetzen",
		entfernen_alle_button: "Alles zurücksetzen",
		public_key_anlegen_hinweis: "Melden Sie sich mittels Face ID, Touch ID, Windows Hello oder einem externen Sicherheitstoken an. Bitte beachten Sie, dass Ihr Gerät eine solche Anmeldung unterstützen muss.",
		public_key_entfernen_hinweis: "Hier können Sie die passwortlose Anmeldung über Face ID, Touch ID, Windows Hello oder mit einem externen Sicherheitstoken zurücksetzen.",
		email_benachrichtigungen: "E-Mail Benachrichtigungen",
		email_benachrichtigungen_label: "Ja, ich möchte monatlich über neue Verbrauchsdaten informiert werden.",
		email_benachrichtigungen_aktualisiert: "Die Einstellungen zu E-Mail Benachrichtigungen wurden aktualisiert."
	},
	passwort_check: {
		eingegebene_zeichen: "eingegebene Zeichen: {0}",
		mindestens_zeichen: "mindestens {0} Zeichen",
		mindestens_grossbuchstabe: "mindestens 1 Großbuchstabe",
		mindestens_kleinbuchstabe: "mindestens 1 Kleinbuchstabe",
		mindestens_symbol: "mindestens 1 Symbol",
		mindestens_zahl: "mindestens 1 Zahl",
	},
	kontakt: {
		anfrage: "Kontaktanfrage",
		nachricht: "Ihre Nachricht",
		absenden: "Absenden",
		screenshot: "Screenshot (optional)",
		screenshot_hinweis: "Erlaubte Dateiformate: .jpg, .png, .webp, .pdf, .heic; Maximale Größe: 5 MB",
		erforderlich: "Bitte geben Sie eine Nachricht an.",
		max_size_validation: "Bitte wählen sie eine Datei mit max {0} aus.",
		ungueltige_dateiendung: "Die ausgewählte Datei hat eine ungültige Dateiendung.",
		anfrage_gesendet: "Ihre Anfrage ist eingegangen und wird demnächst bearbeitet.",
		einfuehrung: "Bitte teilen Sie uns hier Ihr Anliegen mit, gerne auch mit Screenshots.",
	},
	mitbenutzer: {
		verwalten: "Mitbenutzer verwalten",
		hinzufuegen: "Mitbenutzer Hinzufügen",
		hinzufuegen_hinweis: "Hier können Sie einen Mitbenutzer anlegen, der ebenfalls Zugriff auf die Verbrauchsdaten zu Ihrem Vertrag erhalten soll:",
		vorname: "Vorname",
		nachname: "Nachname",
		anrede: "Anrede",
		herr: "Herr",
		frau: "Frau",
		divers: "Divers",
		eingabe_erforderlich: "Dies ist ein Pflichtfeld.",
		email_ungueltig: "Bitte geben Sie eine gültige E-Mail-Adresse ein.",
		anlegen: "Anlegen",
		speichern_erfolgreich: "Der Mitbenutzer wurde erfolgreich angelegt. Er erhält in Kürze eine E-Mail mit weiteren Anweisungen.",
		ihr_mitbenutzer: "Zu Ihrem Vertrag ist folgender Mitbenutzer hinterlegt:",
		entfernen: "Entfernen",
		entfernt: "Der Mitbenutzer wurde aus Ihrem Vertrag entlassen und hat zukünftig keinen Zugriff mehr auf Ihre Verbrauchsdaten.",
		entfernen_bestaetigung: "Sind Sie sicher?",
		entfernen_hinweis: "Möchten Sie den Mitbenutzer {0} wirklich aus Ihrem Vertrag entfernen und ihm damit den Zugriff auf Ihre Verbrauchsdaten entziehen?",
	}
}
