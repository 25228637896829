import Vue from 'vue';
import Router from 'vue-router';

// Routes
import paths from './paths';

function route (path, name, meta = {}) {
	if(!name) {
		name = path.charAt(1).toUpperCase() + path.slice(2);
	}
	return {
		name,
		path,
		meta,
		component: (resovle) => import(
			`@/views/${name}.vue`
			).then(resovle)
	}
}

Vue.use(Router);

// Create a new router
const router = new Router({
	mode: 'history',
	routes: paths.map(path => route(path.path, path.name, path.meta)).concat([
		{ path: '*', redirect: '/login' },
	]),
});

export default router;
