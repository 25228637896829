import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import de from './../locales/de';
import moment from 'moment';
//import en from './../locales/en';
import '@/sass/fonts.sass';

Vue.use(Vuetify);

const defaultLanguage = 'de';
const locales = { de };
const browserLanguage = (navigator.language || navigator.userLanguage).substr(0,2);
const locale = typeof locales[browserLanguage] === "undefined" ? defaultLanguage : browserLanguage;
moment.locale(locale);
Vue.prototype.moment = moment;

export default new Vuetify({
	lang: {
		locales: locales,
		current: locale,
	},
	icons: {
		iconfont: 'mdi',
	},
	theme: {
		options: {
			customProperties: true,
		},
		themes: {
			light: {
				primary: '#55AFC8',
				secondary: '#B90F5F',
				accent: '#D5B076',
				anchor: '#343B3B',
			},
		},
	},
});
