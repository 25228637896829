<template>
  <v-app>
    <Header/>
    <Navigation/>
    <v-main class="main__authenticated">
      <slot/>
    </v-main>
    <AppSnackbar/>
    <AppAlertDialog/>
    <div id="impersonation-banner" v-if="impersonation">
      <div class="row">
        <div class="col text-left align-self-center">
          {{ $vuetify.lang.t('$vuetify.impersonation_banner', user.email) }}
        </div>
        <div class="col text-right align-self-center flex-grow-0 flex-shrink-1">
          <v-btn to="/logout" text color="white">
            <v-icon>mdi-logout</v-icon>
            {{ $vuetify.lang.t('$vuetify.header.logout') }}
          </v-btn>
        </div>
      </div>
    </div>
  </v-app>
</template>

<script>
import Header from '@/components/Header';
import Navigation from '@/components/Navigation';
import AppSnackbar from '@/components/AppSnackbar';
import AppAlertDialog from '@/components/AppAlertDialog';
import EventBus from '@/utils/event-bus';
import { mapState } from 'vuex';

export default {
  components: {
    Navigation,
    Header,
    AppSnackbar,
    AppAlertDialog,
  },
  data() {
    return {
      impersonation: false,
    };
  },
  computed: {
    ...mapState({
      user: state => state.user,
    })
  },
  mounted() {
    let vermieter = this.$store.state.vertrag.wohneinheit.gebaeude.liegenschaft.vermieter;
    EventBus.$emit('changeVermieter', vermieter);

    const token = JSON.parse(atob(localStorage.getItem('token').split('.')[1]));
    this.impersonation = !!token.data.impersonation;
  }
}
</script>
