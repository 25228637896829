<template>
  <v-app-bar app dark clipped-right color="primary" id="app-header">
    <v-btn v-on:click="toggleNavigation" v-show="$vuetify.breakpoint.smAndDown" v-if="user.id" icon>
      <v-icon>mdi-menu</v-icon>
    </v-btn>
    <v-toolbar-title>
      <v-img :src="logo" :max-height="$vuetify.breakpoint.smAndDown ? 34 : 50" max-width="200" contain/>
    </v-toolbar-title>
    <v-spacer></v-spacer>
    <v-menu offset-y v-if="user.id">
      <template v-slot:activator="{on, attrs}">
        <v-btn icon v-bind="attrs" v-on="on">
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>
      </template>
      <v-list dense>
        <v-list-item v-for="(item, i) in menuItems" :key="i" :to="item.target">
          <v-list-item-icon v-if="item.icon">
            <v-icon>{{item.icon}}</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ $vuetify.lang.t('$vuetify.' + item.title) }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>

<script>
import EventBus from '@/utils/event-bus';
import { mapState } from 'vuex';

export default {
  data: () => ({
    drawer: true,
    logo: '/assets/images/logo.svg',
    menuItems: [
      {icon: 'mdi-lock', title: 'header.anmeldedaten', target: '/anmeldedaten'},
      {icon: 'mdi-logout', title: 'header.logout', target: '/logout'},
    ]
  }),
  computed: {
    ...mapState({
      user: state => state.user,
    })
  },
  methods: {
    toggleNavigation: () => {
      EventBus.$emit('toggleNavigation');
    }
  },
  mounted () {
    EventBus.$on('changeVermieter', vermieter => {
      if(vermieter.logo) {
        this.logo = process.env.VUE_APP_API_URL + '/var/uploads/' + vermieter.logo;
      } else {
        this.logo = '/assets/images/logo.svg';
      }
    });
  },
};
</script>
