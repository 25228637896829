export default {
	namespaced: true,
	state: {
	},
	mutations: {
		load(state, data) {
			Object.assign(state, data);
		},
		reset(state) {
			Object.keys(state).forEach(key => {
				delete state[key];
			});
		},
		updateMitbenutzerForVertrag(state, data) {
			state.vertraege.forEach(vertrag => {
				if(vertrag.id === data.id) {
					vertrag.mitbenutzer = data.mitbenutzer;
				}
			})
		}
	},
	actions: {
		destroy({ commit }) {
			commit('reset');
		}
	}
}
