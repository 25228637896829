<template>
  <v-snackbar
      :value.sync="show"
      :timeout="timeout"
      :color="color"
      @input="change"
  >
    {{message}}
    <template v-slot:action="{ attrs }">
      <v-btn
          x-small
          text
          icon
          v-bind="attrs"
          @click="show = false"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import { mapGetters } from 'vuex';
import EventBus from '@/utils/event-bus';

export default {
  computed: {
    show: {
      get() {
        return this.$store.state.snackbar.show;
      },
      set(value) {
        this.$store.commit('snackbar/setShow', value);
      }
    },
    ...mapGetters('snackbar', {
      timeout: 'getTimeout',
      color: 'getColor',
      message: 'getMessage',
    }),
  },
  mounted() {
    EventBus.$on('ShowSnackbar', payload => {
      this.$store.commit('snackbar/set', payload);
    });
  },
  methods: {
    change(value) {
      this.$store.commit('snackbar/setShow', value);
    }
  }
}
</script>
