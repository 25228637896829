<template>
  <component :is="layout">
    <router-view />
  </component>
</template>

<script>
import EventBus from '@/utils/event-bus';

export default {
  name: 'App',
  computed: {
    layout() {
      if(this.$route.fullPath === '/') {
        return 'NoLayout';
      }
      return (this.$route.meta.layout || 'DefaultLayout');
    }
  },
  mounted() {
    EventBus.$on('changeVermieter', vermieter => {
      let defaults = this.$vuetify.theme.defaults.light;
      this.$vuetify.theme.themes.light.primary = vermieter.primaer ? vermieter.primaer : defaults.primary;
      this.$vuetify.theme.themes.light.secondary = vermieter.sekundaer ? vermieter.sekundaer : defaults.secondary;
      this.$vuetify.theme.themes.light.accent = vermieter.tertiaer ? vermieter.tertiaer : defaults.accent;
    });
  }
}
</script>

<style lang="sass">
.v-main
  background: #F8F8F8
  padding-top: calc(50px + env(safe-area-inset-top, 0px)) !important
  padding-bottom: env(safe-area-inset-top, 0px) !important

.drawer
  top: calc(50px + env(safe-area-inset-top, 0px)) !important

h1
  font-weight: 200
  font-size: 1.875rem

.v-toolbar__title .v-image__image--contain
  background-position: left center !important

.v-app-bar--fixed, .v-app-bar--fixed > .v-toolbar__content
  height: calc(50px + env(safe-area-inset-top, 0px)) !important
.v-app-bar--fixed > .v-toolbar__content
  padding-top: env(safe-area-inset-top, 0px)

#impersonation-banner
  position: fixed
  bottom: 0
  left: 0
  right: 0
  background: #f00
  text-align: center
  color: #fff
  padding: 4px 1rem
  z-index: 4

@media only screen and (min-width: 960px)
  #app-header
    left: 0!important
  .drawer
    top: calc(80px + env(safe-area-inset-top, 0px)) !important
    z-index: 4 !important
  .v-main
    padding-top: calc(80px + env(safe-area-inset-top, 0px)) !important
  .main__authenticated > .v-main__wrap
    padding: 12px 26px
  .v-app-bar--fixed, .v-app-bar--fixed > .v-toolbar__content
    height: calc(80px + env(safe-area-inset-top, 0px)) !important
</style>
