export default {
	namespaced: true,
	state: {
		show: false,
		message: '',
		buttonLabel: 'OK',
		title: '',
		callback: ()=>{},
	},
	getters: {
		getShow: state => state.show,
		getMessage: state => state.message,
		getButtonLabel: state => state.buttonLabel,
		getTitle: state => state.title,
		getCallback: state => state.callback,
	},
	mutations: {
		set(state, data) {
			Object.assign(state, data);
		},
		setShow(state, value) {
			state.show = value;
		},
		setMessage(state, value) {
			state.message = value;
		},
		setButtonLabel(state, value) {
			state.buttonLabel = value;
		},
		setTitle(state, value) {
			state.title = value;
		},
		setCallback(state, value) {
			state.callback = value;
		},
	}
}
