<template>
  <v-app>
    <Header/>
    <v-main>
      <slot/>
      <AppSnackbar/>
      <AppAlertDialog/>
    </v-main>
  </v-app>
</template>

<script>
import Header from '@/components/Header';
import AppSnackbar from '@/components/AppSnackbar';
import AppAlertDialog from '@/components/AppAlertDialog';
import client from '@/utils/client';
import EventBus from '@/utils/event-bus';

export default {
  components: {
    Header,
    AppSnackbar,
    AppAlertDialog,
  },
  mounted() {
    if(window.location.hostname.search(/^[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}$/) < 0 && (process.env.VUE_APP_ENVIRONMENT === 'Production' || process.env.VUE_APP_ENVIRONMENT === 'Development')) {
      let subdomain = window.location.host.split('.').length > 2 ? window.location.host.split('.')[0] : false;
      if(subdomain && subdomain !== 'www' && subdomain !== 'mieter') {
        client.get('/public/vermieter/' + subdomain).then(response => {
          EventBus.$emit('changeVermieter', response.data);
        });
      }
    }
  }
}
</script>
