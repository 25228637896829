<template>
  <v-dialog v-model="dialog" max-width="600" scrollable persistent>
    <v-form ref="kontaktForm">
      <v-card :loading="sending">
        <v-card-title>
          {{ $vuetify.lang.t('$vuetify.kontakt.anfrage') }}
          <v-spacer></v-spacer>
          <v-btn icon @click="close()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <p>{{$vuetify.lang.t('$vuetify.kontakt.einfuehrung')}}</p>
          <v-textarea
            v-model="message"
            filled
            :label="$vuetify.lang.t('$vuetify.kontakt.nachricht')"
            :rules="messageRules"
          ></v-textarea>
          <v-file-input
              v-model="attachment"
              :label="$vuetify.lang.t('$vuetify.kontakt.screenshot')"
              accept=".jpg,.jpeg,.png,.pdf,.webp,.heic,image/jpeg,image/png,image/webp,image/heic,application/pdf"
              append-icon="mdi-paperclip"
              :hint="$vuetify.lang.t('$vuetify.kontakt.screenshot_hinweis')"
              prepend-icon=""
              filled
              :rules="uploadRules"
          ></v-file-input>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="close()" depressed>
            {{ $vuetify.lang.t('$vuetify.abbrechen') }}
          </v-btn>
          <v-btn @click="send()" color="primary" depressed :loading="sending">
            {{ $vuetify.lang.t('$vuetify.kontakt.absenden') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import client from '@/utils/client';
import { mapState } from 'vuex';

export default {
  props: ['open'],
  data() {
    return {
      dialog: false,
      message: '',
      attachment: null,
      messageRules: [],
      uploadRules: [],
      sending: false,
    }
  },
  computed: {
    ...mapState({
      vertrag: state => state.vertrag,
    })
  },
  watch: {
    open: {
      handler() {
        if(this.open) {
          this.dialog = true;
        }
      }
    }
  },
  mounted() {
    this.messageRules = [
      v => !!v || this.$vuetify.lang.t('$vuetify.kontakt.erforderlich'),
    ];
    this.uploadRules = [
      v => !v || v.size < 5000000 || this.$vuetify.lang.t('$vuetify.kontakt.max_size_validation', '5 MB'),
      v => !v || /\.(jpg|jpeg|png|pdf|webp|heic)$/i.test(v.name) || this.$vuetify.lang.t('$vuetify.kontakt.ungueltige_dateiendung'),
    ];
  },
  methods: {
    close() {
      this.dialog = false;
      this.$emit('close');
    },
    send() {
      if(this.$refs['kontaktForm'].validate()) {
        let data = new FormData();
        data.append('message', this.message);
        data.append('vertrag_id', this.vertrag.id);
        if(this.attachment) {
          data.append('attachment', this.attachment);
        }
        this.sending = true;
        client.post('/kontakt/mieter', data).then(() => {
          this.sending = false;
          this.message = '';
          this.attachment = null;
          this.$store.commit('snackbar/set', {
            show: true,
            color: 'success',
            message: this.$vuetify.lang.t('$vuetify.kontakt.anfrage_gesendet')
          });
          this.close();
        }).catch(() => {
          this.sending = false;
        });
      }
    }
  }
}
</script>
